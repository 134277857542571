import React from 'react';

interface Props {
    click?: () => void;
    text: string;
    width: any;
}

const EfButtonWhite: React.FC<Props> = props => {

    const buttonWhite = {
        background: '#FFFFFF',
        border: '1px solid #F7B500',
        boxSizing: 'border-box' as 'border-box',
        height: 30,
        color: '#F6BE00',
        borderRadius: '22px',
        width: props.width,
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center' as 'center',
        fontFamily: 'brandon-grotesque, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        cursor:'pointer',
    }

    return (
        <button style={buttonWhite} onClick={props.click}>{props.text}</button>
    )
}

export default EfButtonWhite;

