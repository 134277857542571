import React from "react";


const ChartLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    return (
      <text x={x + width / 2} y={y - radius} fill="#000" textAnchor="middle" dominantBaseline="middle" style={{fontFamily: 'brandon-grotesque, sans-serif',
      fontSize: 'calc(0.6em + 0.1vw)',
      lineHeight: '14px',
      textAlign: 'center' as 'center',
      color: '#9EA4B3',}}>
        {value}
      </text>
    );
};

export default ChartLabel;
