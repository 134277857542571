import React, { useEffect, useState } from "react";
import Carousel from '@brainhubeu/react-carousel';
import Rating from 'react-rating';
import {useHistory} from 'react-router-dom';
import Icon from '../svg/icon.svg';
import StarFull from '../svg/starFull.svg';
import StarEmpty from '../svg/starEmpty.svg';
import EfButtonWhite from './EfButtonWhite';
import EfButtonOrange from './EfButtonOrange';
import Done from '../svg/done.png';
import { Line } from 'rc-progress';

interface QuestionAnswer {
  QuestionId: string;
  Rate: number;
}
let rootObj:QuestionAnswer[] = [];

const IntrestsCarousel: React.FC<{}> = () => {
  let history = useHistory();
  const [questions, setQuestions] = useState(Array(30));
  const [carouselValue, setCarouselValue] = useState(0);
  const [rateValue, setRateValue] = useState([]);
  const [finished, setFinished] = useState(false);

  var rated = false;

  const finishQuestions = (rate, questions, i, isMobile) => {
    const r:QuestionAnswer = Object();
    r.QuestionId = questions[i].Id;
    let newRateValue = rateValue;
    switch(rate) { 
      case 1: { 
        r.Rate = questions[i].Values[0].Value;
        break; 
      } 
      case 2: { 
        r.Rate = questions[i].Values[1].Value;
        break; 
      } 
      case 3: { 
        r.Rate = questions[i].Values[2].Value;
        break; 
      } 
      case 4: { 
        r.Rate = questions[i].Values[3].Value;
        break; 
      } 
      case 5: { 
        r.Rate = questions[i].Values[4].Value;
        break; 
      } 
      default: { 
        break; 
      } 
    } 

    newRateValue[i] = r.Rate;
    setRateValue(newRateValue);

    rootObj[i] = r;
    rated = true;

    if (isMobile) {
      rated = true;
      setTimeout(() => {
        if (carouselValue < 29){
          setCarouselValue(carouselValue + 1);
        }
      }, 200);

    }
  }

  useEffect(() => {
    async function fetchData() {
      fetch('https://career-assessment-api-dev.equalfuture.com/profiler/interests',
            {
              method: 'GET'
            }
           )
           .then(res => res.json())
           .then((data) => {
             const arr = data;
             setQuestions(arr);
           })
           .catch(console.log)

    }
    fetchData();
  }, []);

  const next = (i) => {
    if (rated || rateValue[i]) {
      if (carouselValue < 30) {
        setCarouselValue(carouselValue + 1);
      }
      if (i === 29) {
        setFinished(true);
      }
    }
  }

  const prev = () => {
    if (carouselValue > 0){
      setCarouselValue(carouselValue - 1);
      rated = false;
    } 
  }

  const sendData = () => {
    window.postMessage('completed', '*')
    const answers = JSON.stringify(rootObj);

    fetch('https://career-assessment-api-dev.equalfuture.com/profiler/interests',
          {
            method: 'POST',
            headers: new Headers({
              "Content-Type": "application/json"
            }),
            body: answers,
          }
         )
         .then(res => res.json())
         .then((data) => {
           window.postMessage('{"AssessmentId":'+ '"'+ data.AssessmentId + '"' + ',"AssessmentType":' + '"'+ data.AssessmentType +'"'+ '}', '*');

           if(window.opener){
             window.opener.postMessage('{"AssessmentId":'+'"'+  data.AssessmentId +'"'+ ',"AssessmentType":' + '"'+ data.AssessmentType +'"'+ '}', '*');
           }
           const disc = Array();
           data.Occupations.map((row, i) => {
             disc.push(row);
           })
           history.push("/jobs", {
             jobsList: disc
           });
         })
         .catch(console.log)
  }

  let centered = {position: 'absolute' as 'absolute',
    width:'89%',
    height:'auto' as 'auto', 
    padding: 20,
    userSelect: 'none' as 'none',
    WebkitTouchCallout: 'none' as 'none',
    WebkitUserSelect: 'none' as 'none'};

  return(
    <div style={centered}>
      <div style={{width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center', margin:'auto' as 'auto', marginTop:40}}>
        <img src={Icon} style={{width:'30%', height:'auto'}}/>
      </div>  
      <div style={{width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center', margin:'auto' as 'auto'}}>
        <p style={titleMobile}>CAREER ASSESSMENT</p>
      </div>  
      <Carousel value={carouselValue} onChange={prev} slidesPerPage={1}>
        {questions.map((row, i) =>
        <div key={row.Question} style={cardMobile}>
          {i < questions.length && !finished &&
            <>
              <div style={{marginTop: 0}}>
                <img src={require('../images/'+ i + '.png')} style={{width:'25%', marginTop: 50, marginBottom: 10, WebkitTouchCallout: 'none', WebkitUserSelect: 'none' }}/>
              </div>
              <div style={{marginBottom: 10, WebkitTouchCallout: 'none', WebkitUserSelect: 'none'}}>
                <p style={cuestionLabel}>{row.Question}</p>
              </div>
              <div style={{width:'100%', marginBottom: 20}}>

                <Rating
                  onChange={(rate) => { finishQuestions(rate, questions, i, true) }}
                  emptySymbol={<img alt="empty" src={StarEmpty} style={{ width: '80%', height: 'auto' }} />}
                  fullSymbol={<img alt="full" src={StarFull} style={{ width: '80%', height: 'auto' }} />}
                  initialRating={rateValue[i] != null? rateValue[i] : 0}
                />
              </div>
              <div style={{width:'100%', display: 'flex', justifyContent: 'space-evenly'}}>
                {i !== 0 && <EfButtonWhite width={'35%'} click={prev} text="PREVIOUS" />}
                {rootObj[i] && i < questions.length - 1 && <EfButtonOrange click={() => next(i)} text="NEXT" width={'35%'} />}
                {i === questions.length - 1 && <EfButtonOrange click={() => next(i)} text="DONE" width={'35%'} />}
              </div> 
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width:'70%', marginTop: '22px', marginBottom: '22px'}}>
                  <Line percent={(i + 1) * 100 / questions.length} strokeWidth={4} strokeColor="#F7B500" trailWidth={4} />
                  <div style={{marginTop: '5px', textAlign: 'center', fontSize: '16px', color: '#293861', fontFamily: 'brandon-grotesque'}}>
                    {`${i + 1}/${questions.length}`}
                  </div>
                </div>
              </div>
            </> }
          {finished &&
            <div >
              <p style={subtitleLabelLast}>You are done!</p>
              <div>
                <img src={Done} style={{width:'33%', height:'auto'}}/>
              </div>
              <div style={{width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{margin: '0 40px'}}>
                  <p style={doneLabel}>HERE ARE YOUR POTENTIAL CAREERS</p>
                </div>
              </div>
              <div style={{width: '100%', display:'flex', justifyContent: 'center', marginBottom: 25}}>
                <EfButtonWhite click={sendData} text={"LET'S CHECK THEM OUT"} width={'auto'} />
              </div> 
            </div>}
        </div>)}        
      </Carousel>  
    </div>
  );
}

export default IntrestsCarousel;

const cardMobile = {
  width: "80%",
  display:'block',
  marginLeft:'auto', 
  marginRight:'auto',
  textAlign: "center" as "center",
  background: "#FFFFFF",
  boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
  fontSize: "7px",
  borderRadius: "10px",
  marginBottom: 60,
  marginTop:50,
};
const titleMobile = {
  fontFamily: 'brandon-grotesque, sans-serif',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '22px',
  lineHeight: '35px',
  textAlign: 'center' as 'center',
  color:'#293861'
};

const subtitleLabelLast = {
  fontSize: '24px',
  textAlign: 'center' as 'center',
  fontFamily: 'brandon-grotesque, sans-serif',
  fontWeight: 700,
  color: '#293861'
};

const cuestionLabel = {
  fontSize: 'calc(2em + 0.1vw)',
  textAlign: 'center' as 'center',
  fontFamily: 'brandon-grotesque, sans-serif',
  fontStyle: 'normal',
  margin:20,
  fontWeight: 600,
  letterSpacing: '2.14286px',
  color: '#293861'
};

const doneLabel = {
  fontFamily: 'brandon-grotesque, sans-serif',
  fontWeight: 500,
  fontSize: '20px',
  textAlign: 'center' as 'center',
  letterSpacing: '1.5',
  color: '#293861'
};

