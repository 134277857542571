import React, { Component, useEffect, useState } from "react";
import '@brainhubeu/react-carousel/lib/style.css';
import portfolio from '../svg/porfolio.svg';
import IntrestsCarousel from '../Components/IntrestsCarousel';
import {Stack} from '@fluentui/react';

const Questionnaire: React.FC<{}> = props => {

  return(
      <IntrestsCarousel/>
  );

}

export default Questionnaire;


const container ={
  background: '#F8FAFF',
}