import React from 'react';
import AppNavigator from './AppNavigator';
import { positions, Provider } from "react-alert";

function App() {
  const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER
  };
  return (

    <AppNavigator />

  )
}

export default App;


