import React from "react";
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';

import Questionnaire from "./Screens/Questionnaire";
import Jobs from "./Screens/Jobs";

const appNavigator: React.FC<{}> = props => {
    return (
        <Router>
            <Route exact path="/" component={Questionnaire}>
            </Route>
            <Route exact path="/jobs" component={Jobs}>
            </Route>
        </Router>
    );
};

export default appNavigator;
