import React, { Component } from 'react';

interface Props {
    click?: () => void;
    text: string;
    width: any;
}

interface State {
}

const EfButtonOrange: React.FC<Props> = props => {
    
    const buttonOrange = {
        color: '#FFFFFF',
        background:'#F6BE00',
        borderRadius: '22px',
        height: 30,
        width: props.width,
        border: 'none' as 'none',
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center' as 'center',
        fontFamily: 'brandon-grotesque, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        cursor:'pointer'
    }

    return (
            <button style={buttonOrange} onClick={props.click}>{props.text}</button>
        )
}

export default EfButtonOrange;

